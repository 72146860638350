import { ActionTypes } from "../constants/ActionTypes";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import { ReactProjectBaseUrl } from "../../utils/Constants";
import { axiosFunction } from "../../utils/globalFunctions/axiosFunction";
import { getApiToken } from "../../utils/get-token";
import Cookies from "js-cookie";

export const getAddress = (id) => async (dispatch) => {
  const token = getApiToken();
  const accessToken = Cookies.get("accessToken");

  const response = await axiosFunction(
    token,
    `${ReactProjectBaseUrl}${API_ENDPOINTS.FETCH_ADRESS_LIST}`,
    "GET",
    null,
    accessToken
  );
  const actualResponse = [...response.data.response];
  const splitFUllName = actualResponse[0].firstName;
  actualResponse[0].firstName = splitFUllName.split(" ")[0];
  actualResponse[0].lastName =
    splitFUllName.split(" ")[1] !== undefined
      ? splitFUllName.split(" ")[1]
      : actualResponse[0].lastName;
  dispatch({ type: ActionTypes.GET_ADDRESS, payload: actualResponse });
};
