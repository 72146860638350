import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { fetchCompanyDetailsFromDomainName } from "./State/actions/LoginActions";
import { getBanners } from "./State/actions/BannerActions";
import { getTopCategory } from "./State/actions/TopCategoriesAction";
import { getAddress } from "./State/actions/AddressActions";
import {
  fetchBestSellingProducts,
  fetchProducts,
  fetchTrendingProducts,
  selectedProduct,
} from "./State/actions/productActions";
import { getCategories } from "./State/actions/CategoryActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
import Geocode from "react-geocode";
import { GetWishlist } from "./State/actions/WishlistActions";
import { useGeolocated } from "react-geolocated";
import { GOOGLE_API_KEY } from "./utils/Constants";
import { setGeoLocationFlag } from "./State/actions/DefaultUtilsActions";
import RoutesComponent from "./routes/RoutesComponent";
import { getAds } from "./State/actions/AdvertisementActions";
import { getFaviconGIFLoader } from "./State/actions/FaviconGIFLoaderActions";
import { getApiToken } from "./utils/get-token";

function App() {
  // const domainName = 'mgc.bharatbiz.app';
  // const domainName = "spicesandmore.vasy.in";
  // const domainName = 'spicesandmore.vasy.in';
  // const domainName = "riyapal.vasy.in";
  //  const domainName = "localhost.bharatbiz.app";
  const domainName = window.location.hostname;
  // const domainName = "gajanand.bharatbiz.app";
  // const domainName = "ecommarce.bharatbiz.app";
  // const domainName = "riyastores.bharatbiz.app";
  // const domainName = "vrmart.bharatbiz.app";
  // const domainName = "yogi.bharatbiz.app;
  // const domainName = "ashish1.bharatbiz.app";
  // const domainName = "22feb.bharatbiz.app";
  // const domainName = "ashishprod.vasy.in";
  // const domainName = "localhost.bharatbiz.app";
  // const domainName = "ashish.bharatbiz.app";
  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.companyDetails);
  const [relatedDiv, setRelatedDiv] = useState("addProductVariants");
  const [loadingOnProductQuickPage, setloadingOnProductQuickPage] =
    useState(true);
  const [showProductQuickViewModal, setShowProductQuickViewModal] =
    useState(false);
  const [showTrendingQuickView, setShowTrendingQuickView] = useState(false);
  const [showBestQuickView, setShowBestQuickView] = useState(false);

  const [showHideLoginForm, setshowHideLoginForm] = useState(false);

  const [ShowHideCartComponent, setShowHideCartComponent] = useState(false);

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    dispatch(
      fetchCompanyDetailsFromDomainName(
        domainName === "localhost" ? "riyapal.vasy.in" : domainName
      )
    );
  }, []);
  useEffect(() => {
    const setThemeColors = () => {
      const themecolors = companyDetails?.response?.themecolors;
      if (themecolors) {
        const primaryColor = themecolors.primaryColor;
        const hex2RGB = hexToRgb(primaryColor);
        document.documentElement.style.setProperty(
          "--primary-color",
          "rgb(39, 38, 38, 1)"
        );
        document.documentElement.style.setProperty(
          "--primary-colorRGB",
          "rgb(230, 230, 230, 1)"
        );
        // document.documentElement.style.setProperty(
        //   "--secondary-color",
        //   themecolors.secondaryColor
        // );
        document.documentElement.style.setProperty(
          "--secondary-color",
          "#333333"
        );
        document.documentElement.style.setProperty(
          "--heading-color",
          themecolors.headingColor
        );
        document.documentElement.style.setProperty(
          "--footer-color",
          themecolors.footerColor
        );
        document.documentElement.style.setProperty(
          "--footer-text-color",
          themecolors.footerTextColor
        );
      } else {
        const primaryColor = "#138FC0";
        const hex2RGB = hexToRgb(primaryColor);
        document.documentElement.style.setProperty(
          "--primary-color",
          "rgb(39, 38, 38, 1)"
        );
        document.documentElement.style.setProperty(
          "--primary-colorRGB",
          `rgb(230, 230, 230, 1)`
        );
        document.documentElement.style.setProperty(
          "--secondary-color",
          "#000000"
        );
        document.documentElement.style.setProperty(
          "--heading-color",
          "#447604"
        );
        document.documentElement.style.setProperty("--footer-color", "#3A4E48");
        document.documentElement.style.setProperty(
          "--footer-text-color",
          "#EDEBD7"
        );
      }
    };

    if (getApiToken()) {
      dispatch(getFaviconGIFLoader());
      setThemeColors();
      dispatch(getBanners());
      dispatch(getAds());
      dispatch(getTopCategory());
      dispatch(fetchBestSellingProducts());
      dispatch(fetchTrendingProducts());
      dispatch(getCategories());
      dispatch(GetWishlist(Cookies.get("ID")));
      if (Cookies.get("ID")) {
        dispatch(getAddress(Cookies.get("ID")));
      }
    } else {
      const themecolors = {
        primaryColor: "#000000",
        secondaryColor: "#000000",
        headingColor: "#000000",
        footerColor: "#000000",
        footerTextColor: "#ffffff",
      };
      setThemeColors(themecolors);
    }
  }, [companyDetails, dispatch]);

  useEffect(() => {
    if (ShowHideCartComponent) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [ShowHideCartComponent]);

  const triggerQuickViewModal = (type, id) => {
    if (type === "open") {
      if (id === "TrendingComponent") {
        setShowTrendingQuickView(true);
        setShowHideCartComponent(true);
      } else if (id === "BestComponent") {
        setShowBestQuickView(true);
        setShowHideCartComponent(true);
      } else {
        setShowProductQuickViewModal(true);
        setShowHideCartComponent(true);
      }
    } else {
      setShowProductQuickViewModal(false);
      setShowTrendingQuickView(false);
      setShowBestQuickView(false);
      setShowHideCartComponent(false);
    }
  };

  const getCurrentLocation = async () => {
    if (coords) {
      try {
        Cookies.set("latitude", coords.latitude);
        Cookies.set("longitude", coords.longitude);
        Geocode.setApiKey(GOOGLE_API_KEY);
        const response = await Geocode.fromLatLng(
          coords.latitude,
          coords.longitude
        );
        const address = response.results[0].formatted_address;
        const addressVo = response.results[0].address_components;
        let pincode = "";
        let country = "";
        let state = "";
        let city = "";
        addressVo.forEach((component) => {
          const type = component.types[0];
          switch (type) {
            case "postal_code":
              pincode = component.short_name;
              break;
            case "country":
              country = component.long_name;
              break;
            case "administrative_area_level_1":
              state = component.long_name;
              break;
            case "locality":
              city = component.long_name;
              break;
            default:
              break;
          }
        });

        Cookies.set("COUNTRY", country);
        Cookies.set("STATE", state);
        Cookies.set("CITY", city);
        Cookies.set("label", address);
        Cookies.set("pincode", pincode);
        dispatch(setGeoLocationFlag(true));
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  const commonObj = {
    relatedDiv,
    setRelatedDiv,
    loadingOnProductQuickPage,
    setloadingOnProductQuickPage,
    showProductQuickViewModal,
    setShowProductQuickViewModal,
    showTrendingQuickView,
    showBestQuickView,
    showHideLoginForm,
    setshowHideLoginForm,
    triggerQuickViewModal,
  };
  return (
    <div className="App text-start">
      <BrowserRouter>
        <RoutesComponent {...commonObj} />
      </BrowserRouter>
    </div>
  );
}

export default App;
